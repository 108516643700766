<template>
  <v-form
    ref="form"
    v-model="isvalid"
    lazy-validation
		eager
  >
		<v-card>
			<v-card-title
				class="headline primary dark white--text"
				primary-title
			>
				Update Batch No. {{batchDetails.batch_number}} Information
				<v-spacer/>
				<v-btn icon dark @click="closeDialog(batchDetails.batch_number)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text v-if="batchDetails">
				<br>
				<h4>Disbursement Details</h4>
				<br>
				<v-container grid-list-md>
					<v-layout wrap>
						<v-flex v-if="documentDetails[0].approved_remarks !== undefined && documentDetails[0].approved_remarks !== '' && documentDetails[0].approved_remarks !== null && batchDetails.status === 1" xs12 sm12 md12>
							<v-alert type="error">
								This disbursement has been rejected: Reason - {{documentDetails[0].approved_remarks}}
							</v-alert>
						</v-flex>
						<v-flex xs12 sm12 md12>
							<p>Select Payment Method:</p>
							<v-radio-group v-model="batchDetails.document_type" row readonly>
								<v-radio label="Debit Memo" value="DM"></v-radio>
								<v-radio label="Cheque / CV" value="CV"></v-radio>
								<!-- <v-radio label="CV" :value="2"></v-radio> -->
								<v-radio label="Online / Others" value="OB"></v-radio>
								<v-radio label="Cheque Write" value="CW"></v-radio>
								<!-- <v-radio label="Others" :value="4"></v-radio> -->
							</v-radio-group>
						</v-flex>
						<v-flex xs12 sm6 md4>
							<v-text-field label="Batch Number" v-model="batchDetails.batch_number" :rules="batch_number_rules" required></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md4>
							<v-menu
								v-model="batch_datepicker"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
								:disabled="batchDetails.document_type != 'OB'"
								:close-on-content-click="false"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										label="Date"
										v-model="document_date"
										required
										:readonly="batchDetails.document_type != 'OB'"
										:rules="date_rules"
										return-masked-value
										v-on="on"
										mask="YYYY-MM-DD"
										placeholder="YYYY-MM-DD">
									</v-text-field>
								</template>
								<v-date-picker
									color="primary"
									v-model="document_date"
									@change="batchDatepickerSelect(document_date)"
								></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex xs12 sm6 md4>
							<v-autocomplete
								v-model="batchDetails.bank_account_id"
								:items="dataBanks"
								editable
								hide-details
								item-text="code_name"
								item-value="id"
								label="Bank"
								:rules="bank_rules"
								@change="changeAccount"
							>
								<template slot="item" slot-scope="data">
									{{ data.item.bank_code }} - {{ data.item.bank_acct }} - {{ data.item.acct_type }} - {{data.item.acct_clss}}
								</template>
							</v-autocomplete>
						</v-flex>
						<v-flex xs12 sm6 md4>
							<v-autocomplete
								v-model="currency_id"
								:items="currency"
								editable
								hide-details
								readonly
								item-text="cur_desc"
								item-value="id"
								label="Currency"
								@change="changeCOA"
							></v-autocomplete>
						</v-flex>
						<v-flex xs12 sm6 md4>
							<v-text-field label="Rate" v-model="dollar_rate" v-if="currency_id !== 1"></v-text-field>
						</v-flex>
						<!-- <v-flex xs12 sm6 md4>
							<v-text-field label="Amount" v-model="amount" required></v-text-field>
						</v-flex> -->
						<v-layout wrap >
							<v-flex xs12 sm12 md12>
								<h3>Attached Files:</h3>
								<v-layout column style="height: 100px">
									<v-flex style="overflow: auto" class="elevation-3">
									<v-data-table
										:headers="attHeaders"
										:items="trxdisbDetailed"
										hide-default-header
										:loading="false"
										class="elevation-1"
										:hide-default-footer="true"
										style="margin:0"
									>
										<v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
										<template v-slot:items="props">
										<td>{{ props.item.title }}</td>					
										</template>
										<template v-slot:item.file="{ item }">
										<v-icon class="text-right mr-2" color="primary" medium @click="viewItem(item.path)"
											>mdi-eye-circle</v-icon
										>
										<v-icon class="text-right mr-2" color="red" medium @click="deleteItem(item)"
											>mdi-delete</v-icon
										>
										</template>
									</v-data-table>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>	
						<v-layout wrap v-if="documentDetails[0].integrate_from === 3">
							<v-flex md12 xs12 sm12>
								<h4>PO Details</h4>
							</v-flex>
							<v-flex xs12 sm6 md4>
									<v-text-field
										v-model="documentDetails[0].trx_purchase_order[0].po_number"
										readonly
										label="PO Number"
									></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										v-model="po_amount"
										readonly
										label="Total PO Amount"
									></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
								<v-text-field
									v-model="paid_amount"
									readonly
									label="Total Paid Amount"
								></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										v-model="remaining_amount"
										readonly
										label="Remaining Amount to Pay"
									></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										v-model="payment_amount"
										label="Payment Amount"
										:rules="default_rules"
									></v-text-field>
								</v-flex>
								<v-spacer></v-spacer>
								<v-flex xs12 sm12 md4>
								<h3>PO Attached Files:</h3>
								<v-layout column style="height: 100px">
								<v-flex style="overflow: auto" class="elevation-3">
								<v-data-table
									:headers="attHeaders"
									:items="JSON.parse(documentDetails[0].trx_purchase_order[0].upload_file)"
									:loading="false"
									class="elevation-1"
									:hide-default-footer="true"
									:hide-default-header="true"
									style="margin:0"
									:items-per-page="1000"
									>
									<template v-slot:[`item.filename`]="{ item }">{{item.title}}</template>
									<template v-slot:[`item.file`]="{ item }">
										<v-icon class="text-right mr-2" color="primary" medium @click="viewItem(item.path)">
											mdi-eye-circle
										</v-icon>
									</template>
								</v-data-table>
								</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
						<div class="container">
							<div class="large-12 medium-12 small-12 cell">
								<label>Files
									<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
								</label>
							</div>
						</div>
					</v-layout>
					<v-checkbox 
						v-model="batchDetails.isConfidential" 
						label="Save this disbursement form as Confidential?"
						false-value="0"
						true-value="1"
					></v-checkbox>
				</v-container>
				<br>
				<h2>Accounting Entries</h2>
				<trx-header 
					ref="trxHeader" 
					:batchDetails.sync="batchDetails" 
					:documentDetails.sync="documentDetails" 
					:dataAtc.sync="dataAtc"
					:dataDepts.sync="dataDepts"
					:dataBanks.sync="dataBanks"
					:dataSections.sync="dataSections"
					:currency_id.sync="currency_id"
				></trx-header>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn 
					text 
					color="blue accent-4" 
					@click="saveChange = true"
				>
					Save Changes to Draft
				</v-btn>
				<v-btn
					text 
					color="black accent-4"
					@click="updateDisbursement = true"
				>
				Submit for Approval
				</v-btn>
				<v-btn
					text 
					color="red accent-4"
					v-if="this.documentDetails[0].status === 1 && (this.documentDetails[0].edit_by === this.currUser.id)"
					@click="cancelDisbursement = true"
				>
				Archive Disbursement
				</v-btn>
				<!-- <v-btn
					color="primary"
					text
					@click="closeDialog(batchDetails.batch_number)"
				>
				Dismiss
				</v-btn> -->
			</v-card-actions>
			<div class="text-center">
				<v-dialog
					v-model="saveChange"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Save Disbursement
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to save the changes on Batch No. {{batchDetails.batch_number}}?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="saveChanges(batchDetails.batch_number)"
							>
								Save
							</v-btn>
							<v-btn
								color="red"
								text
								@click="saveChange = false"
							>
								Cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="updateDisbursement"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Update Disbursement
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to update Batch No. {{batchDetails.batch_number}}?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="update(batchDetails.batch_number)"
							>
								Update
							</v-btn>
							<v-btn
								color="red"
								text
								@click="updateDisbursement = false"
							>
								Cancel
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
			<div class="text-center">
				<v-dialog
					v-model="cancelDisbursement"
					persistent max-width="600"
				>
					<v-card>
						<v-card-title
							class="headline primary white--text"
							primary-title
						>
							Cancel Disbursement
						</v-card-title>
						<br>
						<v-card-text>
							Are you sure you want to cancel the disbursement of Batch No. {{batchDetails.batch_number}}?
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="cancel(batchDetails.batch_number)"
							>
								Yes
							</v-btn>
							<v-btn
								color="red"
								text
								@click="cancelDisbursement = false"
							>
								No
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-card>
		<v-snackbar
			v-model="snackbar"
			right
		>
			{{ error_message }}
			<v-btn
				color="pink"
				text
				@click="snackbar = false"
			>
				Close
			</v-btn>
		</v-snackbar>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { mask } from 'vue-the-mask';
import jsonToFormData from 'json-form-data';
import { format, startOfMonth, addYears, addMonths, parseISO, getMonth, getYear } from 'date-fns';
import trxHeader from './editTrxHeader';

export default {
	props: {
		batchDetails: Array,
		documentDetails: Array
	},
	components: {
		'trx-header': trxHeader
	},
	directives: {
		mask,
	},
	data() {
		return {
			payment_amount: 0,
			remaining_amount: 0,
			po_amount: 0,
			paid_amount: 0,
			po_request: {},
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			level1: {},
			level2: {},
			level3: {},
			nextPageDepts: '',
			nextPagePayee:'',
			nextPageAcctmainperiod:'',
			nextPageBanks:'',
			nextPageCoas:'',
			dataCoas: [],
			nextPageAtc:'',
			dataRegularTrx: [],
			nextPageRegularTrx:'',
			dataSections: [],
			nextPageSections: '',
			dataAtc: [],
			dataBanks: [],
			dataAcctmainperiod: [],
			dataDepts: [],
			dataPayee: [],
			batch_datepicker: false,
			cheque_datepicker: [],
			due_datepicker: [],
			voucher_datepicker: [],
			details: this.documentDetails,
			date_format: '####-##-##',
			overallDebit: 0,
			overallCredit: 0,
			populate_entries: [],
			regular_trx: [],
			generated_cheques: [],
			snackbar: false,
			isvalid: false,
			saveChange: false,
			updateDisbursement: false,
			cancelDisbursement: false,
			image_title: [],
			upload_file: [],
			delete_file: [],
			disbursement_type: [],
			regular_trx_type: [],
			txn_type: [],
			upload_files: '',
			deleted_files: [],
			file: '',
			minDate: '',
			maxDate: '',
			document_date: '',
			currency_id: 0,
			dollar_rate: '',
			error_message: 'Error: Please fill out the required fields',
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
			default_rules: [
				v => !!v || 'Required Field',
			],
			attHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'title'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			]
		};
	},
	computed: {
		...mapGetters({
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			payeefile: 'payefile/payefiles',
			banks: 'bank/banks',
			bpbanks: 'bpbank/bpbanks',
			payeebanks: 'payeebank/payeebanks',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			// transactions: 'transaction/transactions',
			headers: 'trxdisb/headers',
			regulartrx: 'regularTxns/regulartxns',
			editDialog: 'trxdisb/editDialog',
			currUser: 'auth/currUser',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			trxdisbDetailed: 'trxdisb/trxdisbDetailed',
			currency: 'currency/currencies',
			approvinglevel: 'approvinglevel/approvinglevels',
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	watch: {
		approvinglevel: {
			handler(val) {
				val.forEach(level => {
					if (level.level_code == 1){
						this.level1 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 2){
						this.level2 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 3){
						this.level3 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					}
				});
			}
		},
		payment_amount :{
			handler(val) {
				this.payment_amount = numeral(val).format('0,0.[00]');
			}
		},
		acctperiodmains: {
			handler() {
				this.getAcctperiodmain();
			}
		},
		batchDetails: {
			handler(val) {
				this.getDefaultCurrency();
				this.$emit('update:batchDetails', val);
			},
			deep: true
		},
		banks: {
			handler() {
				this.getDataBank();
			}
		},
		coas: {
			handler() {
				this.getDataCoas();
			}
		},
		payees:{
			handler(){
				this.getPayeeBank();
			}
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
		sections: {
			handler() {
				this.getDataSections();
			}
		},
		documentDetails: {
			handler(val) {
				if(val[0].trx_purchase_order.length > 0){
					this.po_request = val[0].trx_purchase_order[0];
					this.po_amount = numeral(val[0].trx_purchase_order[0].amount).format('0,0.[00]');
					this.paid_amount = numeral(val[0].trx_purchase_order[0].payment_amount).format('0,0.[00]');
					this.remaining_amount = numeral(val[0].trx_purchase_order[0].amount - val[0].trx_purchase_order[0].payment_amount).format('0,0.[00]');
					this.payment_amount = numeral(val[0].po_payment).format('0,0.[00]');
				}
				
				this.dollar_rate = val[0].dollar_rate;
				this.document_date = val[0].document_date;	

				val.forEach((header) => {					
					let payee_id = '';
					let department_id = '';
					let section_id = '';
					let branch_id = '';

					let startDate = '';
					let endDate = '';

					let batchDocDate = '';

					if (this.document_date) {
						batchDocDate = format(parseISO(this.document_date), 'yyyy-MM-dd');
					} else {
						batchDocDate = format(new Date(),'yyyy-MM-dd');
					}
					
					let formatStartDate = '';
					let formatEndDate = '';

					startDate = startOfMonth(parseISO(batchDocDate), 1);
					endDate = addYears(parseISO(batchDocDate), 1);

					formatStartDate = format(startDate, 'yyyy-MM-dd');
					formatEndDate = format(endDate, 'yyyy-MM-dd');

					header.minDate = formatStartDate;
					header.maxDate = formatEndDate;	
					
					if (header.document_type === 'CW') { 
						if(header.cw_details.length !== 0){
							payee_id = header.cw_details[0].payee_id;
							branch_id = header.cw_details[0].branch_id;
							department_id = header.cw_details[0].department_id;
							section_id = header.cw_details[0].section_id;
						}

						header.cw_details.forEach((detail) => {
							detail.debit_amount = numeral(detail.debit_amount).format('0,0.[00]');
							detail.credit_amount = numeral(detail.credit_amount).format('0,0.[00]');
						});
					} else {
						if(header.details.length !== 0){
							payee_id = header.details[0].payee_id;
							branch_id = header.details[0].branch_id;
							department_id = header.details[0].department_id;
							section_id = header.details[0].section_id;
						}

						header.details.forEach((detail) => {
							detail.debit_amount = numeral(detail.debit_amount).format('0,0.[00]');
							detail.credit_amount = numeral(detail.credit_amount).format('0,0.[00]');
						});
					}
					

					let countOutrightEntry = [];

					if (header.check_type === 'CHK') {
						if (parseFloat(header.cheque_amount) > 0) {
							let addCheckDetail = '';
							if (header.document_type === 'CW') {
								countOutrightEntry = header.cw_details.filter(function(detail) {
									return detail.remarks === 'Outright Amount' && detail.document_number === header.document_number;
								});

								header.cw_details.filter((detail) => {
									if (countOutrightEntry.length > 1) {
										header.cw_details.filter((detail, index) => {
											if (detail.remarks === 'Outright Amount') {
												header.cw_details.splice(index, 1);
											}
										});
									}

									if (countOutrightEntry.length > 0) {
										if (detail.remarks === 'Outright Amount') {
											detail.payee_id = payee_id;
											detail.department_id = department_id;
											detail.section_id = section_id;
											detail.regionalhead_id = 1;
											detail.branch_id = branch_id;
											detail.document_number = header.document_number;
											detail.document_date = this.document_date;
											detail.remarks = 'Outright Amount';
											detail.debit_amount = numeral(0).format('0,0.[00]');
											detail.credit_amount = numeral(header.cheque_amount).format('0,0.[00]');
											
											this.dataDepts.forEach((dept_detail) => {
												if (dept_detail.id === department_id) {
													detail.sections = dept_detail.sections;
												}
											});
										}
									} else {
										addCheckDetail = {
											payee_id: payee_id,
											department_id: department_id,
											section_id: section_id,
											regionalhead_id: 1,
											branch_id: branch_id,
											document_number: header.document_number,
											document_date: this.document_date,
											remarks: 'Outright Amount',
											debit_amount: numeral(0).format('0,0.[00]'),
											credit_amount: numeral(header.cheque_amount).format('0,0.[00]')
										};
									}
								});

								if (countOutrightEntry.length < 1) {
									header.details.splice(1, 0, addCheckDetail);
								}
							} else {
								countOutrightEntry = header.details.filter(function(detail) {
									return detail.remarks === 'Outright Amount' && detail.document_number === header.document_number;
								});

								header.details.filter((detail) => {
									if (countOutrightEntry.length > 1) {
										header.details.filter((detail, index) => {
											if (detail.remarks === 'Outright Amount') {
												header.details.splice(index, 1);
											}
										});
									}

									if (countOutrightEntry.length > 0) {
										if (detail.remarks === 'Outright Amount') {
											detail.payee_id = payee_id;
											detail.department_id = department_id;
											detail.section_id = section_id;
											detail.regionalhead_id = 1;
											detail.branch_id = branch_id;
											detail.document_number = header.document_number;
											detail.document_date = this.document_date;
											detail.remarks = 'Outright Amount';
											detail.debit_amount = numeral(0).format('0,0.[00]');
											detail.credit_amount = numeral(header.cheque_amount).format('0,0.[00]');
											
											this.dataDepts.forEach((dept_detail) => {
												if (dept_detail.id === department_id) {
													detail.sections = dept_detail.sections;
												}
											});
										}
									} else {
										addCheckDetail = {
											payee_id: payee_id,
											department_id: department_id,
											section_id: section_id,
											regionalhead_id: 1,
											branch_id: branch_id,
											document_number: header.document_number,
											document_date: this.document_date,
											remarks: 'Outright Amount',
											debit_amount: numeral(0).format('0,0.[00]'),
											credit_amount: numeral(header.cheque_amount).format('0,0.[00]')
										};
									}
								});

								if (countOutrightEntry.length < 1) {
									header.details.splice(1, 0, addCheckDetail);
								}
							}
						} else {
							if (header.document_type === 'CW') { 
								header.cw_details.filter((detail, index) => {
									if (detail.remarks === 'Outright Amount') {
										header.cw_details.splice(index, 1);
									}
								});
							} else {
								header.details.filter((detail, index) => {
									if (detail.remarks === 'Outright Amount') {
										header.details.splice(index, 1);
									}
								});
							}

							
						}

					} else if ((header.check_type === 'PDC')) {
						if (header.document_type === 'CW') { 
							header.cw_details.filter((detail, index) => {
								if (detail.remarks === 'Outright Amount') {
									header.cw_details.splice(index, 1);
								}
							});
						} else {
							header.details.filter((detail, index) => {
								if (detail.remarks === 'Outright Amount') {
									header.details.splice(index, 1);
								}
							});
						}
					}

					let totalDebit = 0;
					let totalCredit = 0;

					if (header.document_type === 'CW') {
						//computes the total debit in a specific document
						totalDebit = header.cw_details.reduce((sum, details) => {
							if (details.debit_amount === '') {
								details.debit_amount = 0;
							}

							return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
						}, 0);

						//computes the total credit in a specific document
						totalCredit = header.cw_details.reduce((sum, details) => {
							if (details.credit_amount === '') {
								details.credit_amount = 0;
							}

							return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
						}, 0);
					} else {
						//computes the total debit in a specific document
						totalDebit = header.details.reduce((sum, details) => {
							if (details.debit_amount === '') {
								details.debit_amount = 0;
							}

							return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
						}, 0);

						//computes the total credit in a specific document
						totalCredit = header.details.reduce((sum, details) => {
							if (details.credit_amount === '') {
								details.credit_amount = 0;
							}

							return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
						}, 0);
					}

					//displays the total below the table
					header.overallDebit = numeral(totalDebit).format('0,0.00');
					header.overallCredit = numeral(totalCredit).format('0,0.00');
				});

				this.$emit('update:documentDetails', val);
			},
			deep: true
		},
		regulartrx: {
			handler(val) {
				this.getDataRegularTrx();

				let filteredResult = this.dataRegularTrx.filter(value => {
					return value.is_approved !== 0;
				});

				this.regular_trx = filteredResult;

				this.documentDetails.forEach((header) => {

					if (header.regular_trx_id > 0) {
						this.populateRegTrxData(header.document_number, val[header.regular_trx_id - 1]);
					}
				});
			},
			deep: true
		},
	},
	created() {
		this.$store.dispatch('bank/getBanks');
		this.$store.dispatch('regularTxns/getregularTxns'); 
		this.$store.dispatch('payefile/getPayefileDropdown'); 
		this.$store.dispatch('currency/getCurrencies');
		this.getBranches();
		this.getSections();
		this.getDepts();
		this.getCoas();
		this.getPayeeBanks();
		// this.getPayeeBank();
	},
	async mounted() {
		await this.$store.dispatch('approvinglevel/getApprovingLevels');
		await this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');
		await this.$store.dispatch('payefile/getPayefileDropdown');
		this.$store.dispatch('acctperiodmain/getAcctperiodmains');
		this.getDropdownItems();
		this.currency_id = this.documentDetails[0].currency_id;
		//this.changeCOA();

		// let distData = this.dist_data;
		// let data = distData.debit_memo;
		
		// if(this.batchDetails.document_type == 'CV'){
		// 	distData['cheque'] = {};
		// 	data = distData.cheque;
		// } else if (this.batchDetails.document_type == 'OB'){
		// 	distData['online'] = {};
		// 	data = distData.online;
		// } else if(this.batchDetails.document_type == 'CW'){
		// 	distData['cheque_write'] = {};
		// 	data = distData.cheque_write;
		// }
		// data['batch_number'] = this.documentDetails[0].batch_number;
		// data['document_type'] = this.batchDetails.document_type;
		// data['document_number'] = this.batchDetails.document_type === 'CW' ? [this.documentDetails[0].reference_number] : [this.documentDetails[0].document_number];
		// data['date'] = format(new Date(), 'yyyy-MM-dd');
		// data['bank_account_id'] = this.documentDetails[0].bank_account_id;
		// data['isConfidential'] = this.documentDetails[0].is_confidential;
		// data['currency_id'] = this.documentDetails[0].currency_id;
		// data['dollar_rate'] = this.documentDetails[0].dollar_rate;

		// this.$store.commit('trxdisb/SET_DIST_DATA', distData);
		// this.$store.commit('app/SET_IS_LOADING', false, { root: true });
		// if (this.documentDetails[0].is_pdc === 1) {
		// 	let first_item = this.documentDetails[0];
		// 	this.$emit('update:documentDetails', [first_item]);
		// }
	},
	methods: {
		deleteItem(item){
			this.trxdisbDetailed.splice(this.trxdisbDetailed.indexOf(item), 1);
			this.deleted_files.push(item.filename);
		},
		viewItem(item) {
			let path = atob(item);
			// fetch(path)
			// 	.then(res => res.blob()) // Gets the response and returns it as a blob
			// 	.then(blob => {
			// 		const objectURL = URL.createObjectURL(blob);
			// 		window.open(objectURL, '_blank');
			// 	});
			window.open(path, '_blank');
		},
		getCurrentDate() {
			return format(new Date(), 'yyyy-MM-dd');
		},
		batchDatepickerSelect(date) {
			if (date) {
				let month = getMonth(parseISO(date));
				let year = getYear(parseISO(date));
				this.dataAcctmainperiod.forEach(account_period => {
					if (account_period.period_no === (month + 1) && account_period.fiscal_year === year) {
						if (account_period.disbursement === 'CLOSED') {
							this.snackbar = true;
							this.$data.error_message = 'Accounting Period for this date is already Closed.';
							this.document_date = this.getCurrentDate();
						}
					}
				});
				this.batch_datepicker = false;
			}
		},
		chequeDatepickerSelect(date, index) {
			if (date) {
				this.cheque_datepicker[index] = false;
			}
		},
		dueDatepickerSelect(date, index) {
			if (date) {
				this.due_datepicker[index] = false;
			}
		},
		voucherDatepickerSelect(date, index) {
			if (date) {
				this.voucher_datepicker[index] = false;
			}
		},
		changeCOA(){
			let data = [];
			this.coaData.map(coa => {
				if(coa.currency_id == this.currency_id){
					data.push(coa);
				}
			});
			this.$store.commit('coa/SET_COA_CURR', data, { root: true });
			this.documentDetails.forEach(docDet => {
				docDet.details.forEach(det => {
					data.forEach(dat => {
						if(dat.acct_code != det.account_code){
							det.account_code = '';
						}
					});
				});
			});
		},
		getDropdownItems() {
			this.documentDetails.forEach(header => {
				header.payee_bank = this.payeebanks;				
				this.payees.filter(payee_details => {
					if (header.payee_id === payee_details.id) {
						header.payee_bank = payee_details.payee_banks;
						if(payee_details.payee_banks.length !== 0 ){
							header.payee_account_id = payee_details.payee_banks[0].payee_account_id;
						} else {
							header.payee_account_id = '';
						}
					}
				});

				let detailsData = [];

				if (header.document_type === 'CW') {
					detailsData = header.cw_details;
				} else {
					detailsData = header.details;
				}
				
				detailsData.forEach((detail, index) => {
					detail.sections = this.dataSections;
					detail.branches = this.branches;
					detail.document_date = '';
					
					this.getDeptData(detail.department_id, index, detail.document_number);

					if (this.document_date) {
						detail.document_date = format(parseISO(this.document_date), 'yyyy-MM-dd');
					} else {
						detail.document_date = format(new Date(),'yyyy-MM-dd');
					}
				});
			});

			// this.$set(this.documentDetails, this.details);
		},
		getCoas(){
			this.$store.dispatch('coa/getCoaDropdown');
		},
		getSections() {
			this.$store.dispatch('section/getSectionDropdown');
		},
		getBranches() {
			this.$store.dispatch('branch/getBranchDropdown');
		},
		getDepts() {
			this.$store.dispatch('dept/getDeptDropdown');
		},
		getAlphaTaxCodes() {
			this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');
		},
		getPayees() {
			this.$store.dispatch('payefile/getPayefileDropdown'); 
		},
		getPayeeBanks() {
			this.$store.dispatch('payeebank/getPayeeBankDropdown');
		},
		getBpBanks() {
			this.$store.dispatch('bpbank/getBpbankDropdown');
		},
		getPayeeBank(){
			this.documentDetails.forEach(headerData => {
				if('payee_id' in headerData){
					let payee_bank = '';
					payee_bank = this.payees.filter(det => { return det.id === headerData.payee_id; });
					if(payee_bank.length !== 0){
						if(payee_bank[0].payee_banks){
							headerData.payee_bank = payee_bank[0].payee_banks;
						} else {
							headerData.payee_bank = [];
						}
					} else {
						this.payeebanks.filter(det => {
							det.item_data = det.account_number;
						});
						headerData.payee_bank = this.payeebanks;
					}
				}		
			});
		},
		getDeptData(dept_id, index, document_number) {
			var filterDetails = [];
			var details = [];
			var concatDetails = [];

			if (this.batchDetails.document_type === 'CW') {
				filterDetails = this.documentDetails.filter(header => header.reference_number === document_number);				
				details = filterDetails[0].cw_details;
				
				this.dataDepts.filter(depts_details => {
					if(depts_details.id == dept_id){
						if (depts_details.sections.length != 0) {
							details[index].sections = depts_details.sections;
							if(details[index].section_id){
								details[index].section_id = depts_details.sections.includes(details[index].section_id) ? filterDetails[index].section_id : '' ;
							}
						} else {
							details[index].sections = [];
							details[index].section_id = 0;
						}

						if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
							details[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
						} else {
							details[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
						}
					}
				});

				concatDetails = filterDetails.concat(details.filter((item) => filterDetails.indexOf(item) < 0));
				this.details = concatDetails;
			} else {
				filterDetails = this.documentDetails.filter(header => header.document_number === document_number);
				details = filterDetails[0].details;
				this.dataDepts.filter(depts_details => {
					if(depts_details.id == dept_id){
						if (depts_details.sections.length != 0) {
							details[index].sections = depts_details.sections;
						} else {
							details[index].sections = [];
						}

						if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
							details[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
						} else {
							details[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
						}
					}
				});

				concatDetails = filterDetails.concat(details.filter((item) => filterDetails.indexOf(item) < 0));
				this.details = concatDetails;
			}

			
		},
		checkTaxBase(is_checked, document_number) {
			let payee_id = '';
			let dept_id = '';
			let branch_id = '';
			let section_id = '';
			this.documentDetails.filter((header) => {
				if (header.document_type === 'CW') {
					if (header.reference === document_number) {
						payee_id = header.cw_details[0].payee_id;
						dept_id = header.cw_details[0].department_id;
						branch_id = header.cw_details[0].branch_id;
						section_id = header.cw_details[0].section_id;

						let filterDetails = header.cw_details.filter((detail) => {
							return detail.remarks === 'Tax Based Amount';
						});

						if (is_checked === '1') {
							header.enable_tax_base = '1';
							if (filterDetails.length > 0) { 
								filterDetails.forEach((detail, index) => {
									if (detail.remarks === 'Tax Based Amount') {
										detail.batch_number = header.batch_number;
										detail.payee_id = payee_id;
										detail.department_id = dept_id;
										detail.section_id = section_id;
										detail.regionalhead_id = 1;
										detail.branch_id = branch_id;
										detail.account_code = this.currency_id === 1 ? 2117001 : 2117002;
										detail.document_number = document_number;
										detail.document_date = this.document_date;
										detail.remarks = 'Tax Based Amount';
										detail.debit_amount = 0;
										detail.credit_amount = header.tax_base_amount * parseFloat((header.atc_rate/100));

										this.getDeptData(dept_id, index, document_number);
									}
								});
							} else {
								header.cw_details.unshift({
									batch_number: header.batch_number,
									payee_id: payee_id,
									department_id: dept_id,
									section_id: section_id,
									regionalhead_id: 1,
									branch_id: branch_id,
									document_number: document_number,
									account_code : this.currency_id === 1 ? 2117001 : 2117002,
									document_date: this.document_date,
									remarks: 'Tax Based Amount',
									debit_amount: 0,
									credit_amount: header.tax_base_amount * parseFloat((header.atc_rate/100))
								});

								this.getDeptData(dept_id, 0, document_number);
							}
						} else {
							header.enable_tax_base = '0';
							header.cw_details.forEach((detail) => {
								if (detail.remarks === 'Tax Based Amount') {
									header.cw_details.splice(0, 1);
								}
							});
						}
					}
				} else {
					if (header.document_number === document_number) {
						payee_id = header.details[0].payee_id;
						dept_id = header.details[0].department_id;
						branch_id = header.details[0].branch_id;
						section_id = header.details[0].section_id;

						let filterDetails = header.details.filter((detail) => {
							return detail.remarks === 'Tax Based Amount';
						});

						if (is_checked === '1') {
							header.enable_tax_base = '1';
							if (filterDetails.length > 0) { 
								filterDetails.forEach((detail, index) => {
									if (detail.remarks === 'Tax Based Amount') {
										detail.batch_number = header.batch_number;
										detail.payee_id = payee_id;
										detail.department_id = dept_id;
										detail.section_id = section_id;
										detail.regionalhead_id = 1;
										detail.branch_id = branch_id;
										detail.document_number = document_number;
										detail.account_code = this.currency_id === 1 ? 2117001 : 2117002;
										detail.document_date = this.document_date;
										detail.remarks = 'Tax Based Amount';
										detail.debit_amount = 0;
										detail.credit_amount = header.tax_base_amount * parseFloat((header.atc_rate/100));

										this.getDeptData(dept_id, index, document_number);
									}
								});
							} else {
								header.details.unshift({
									batch_number: header.batch_number,
									payee_id: payee_id,
									department_id: dept_id,
									section_id: section_id,
									regionalhead_id: 1,
									branch_id: branch_id,
									document_number: document_number,
									document_date: this.document_date,
									account_code : this.currency_id === 1 ? 2117001 : 2117002,
									remarks: 'Tax Based Amount',
									debit_amount: 0,
									credit_amount: header.tax_base_amount * parseFloat((header.atc_rate/100))
								});

								this.getDeptData(dept_id, 0, document_number);
							}
						} else {
							header.enable_tax_base = '0';
							header.details.forEach((detail) => {
								if (detail.remarks === 'Tax Based Amount') {
									header.details.splice(0, 1);
								}
							});
						}
					}
				}
			});
		},
		computeTaxBase(amount, tax_rate, document_number) {
			let payee_id = '';
			let dept_id = '';
			let branch_id = '';
			let section_id = '';
			this.documentDetails.filter((header) => {
				if (header.document_type === 'CW') {
					if (header.reference_number === document_number) {
						payee_id = header.cw_details[0].payee_id;
						dept_id = header.cw_details[0].department_id;
						branch_id = header.cw_details[0].branch_id;
						section_id = header.cw_details[0].section_id;
						//checks if tax base amount is checked
						if (header.enable_tax_base === '1') {

							if (parseFloat(amount) > 0) {

								let filterDetails = header.cw_details.filter((detail) => {
									return detail.remarks === 'Tax Based Amount';
								});

								if (filterDetails.length > 0) { 
									filterDetails.forEach((detail, index) => {
										if (detail.remarks === 'Tax Based Amount') {
											detail.batch_number = header.batch_number;
											detail.payee_id = payee_id;
											detail.department_id = dept_id;
											detail.section_id = section_id;
											detail.regionalhead_id = 1;
											detail.branch_id = branch_id;
											detail.document_number = document_number;
											detail.account_code = this.currency_id === 1 ? 2117001 : 2117002;
											detail.document_date = this.document_date;
											detail.remarks = 'Tax Based Amount';
											detail.debit_amount = 0;
											detail.credit_amount = amount * parseFloat((header.atc_rate/100));

											this.getDeptData(dept_id, index, document_number);
										}
									});
								} else {
									header.cw_details.unshift({
										batch_number: header.batch_number,
										payee_id: payee_id,
										department_id: dept_id,
										section_id: section_id,
										regionalhead_id: 1,
										branch_id: branch_id,
										document_number: document_number,
										document_date: this.document_date,
										account_code : this.currency_id === 1 ? 2117001 : 2117002,
										remarks: 'Tax Based Amount',
										debit_amount: 0,
										credit_amount: amount * parseFloat((header.atc_rate/100))
									});

									//console.log('entered');

									this.getDeptData(dept_id, 0, document_number);
								}
							} else {
								header.cw_details.filter((detail) => {
									if (detail.remarks === 'Tax Based Amount') {
										header.cw_details.splice(0, 1);
									}
								});
							}
						} else {
							header.cw_details.filter((detail) => {
								if (detail.remarks === 'Tax Based Amount') {
									header.cw_details.splice(0, 1);
								}
							});
						}

					}
				} else {
					if (header.document_number === document_number) {
						payee_id = header.details[0].payee_id;
						dept_id = header.details[0].department_id;
						branch_id = header.details[0].branch_id;
						section_id = header.details[0].section_id;
						//checks if tax base amount is checked
						if (header.enable_tax_base === '1') {

							if (parseFloat(amount) > 0) {

								let filterDetails = header.details.filter((detail) => {
									return detail.remarks === 'Tax Based Amount';
								});

								if (filterDetails.length > 0) { 
									filterDetails.forEach((detail, index) => {
										if (detail.remarks === 'Tax Based Amount') {
											detail.payee_id = payee_id;
											detail.department_id = dept_id;
											detail.section_id = section_id;
											detail.regionalhead_id = 1;
											detail.branch_id = branch_id;
											detail.account_code = this.currency_id === 1 ? 2117001 : 2117002;
											detail.document_number = document_number;
											detail.document_date = this.document_date;
											detail.remarks = 'Tax Based Amount';
											detail.debit_amount = 0;
											detail.credit_amount = amount * parseFloat((header.atc_rate/100));

											this.getDeptData(dept_id, index, document_number);
										}
									});
								} else {
									header.details.unshift({
										payee_id: payee_id,
										department_id: dept_id,
										section_id: section_id,
										regionalhead_id: 1,
										branch_id: branch_id,
										document_number: document_number,
										account_code : this.currency_id === 1 ? 2117001 : 2117002,
										document_date: this.document_date,
										remarks: 'Tax Based Amount',
										debit_amount: 0,
										credit_amount: amount * parseFloat((header.atc_rate/100))
									});

									this.getDeptData(dept_id, 0, document_number);
								}
							} else {
								header.details.filter((detail) => {
									if (detail.remarks === 'Tax Based Amount') {
										header.details.splice(0, 1);
									}
								});
							}
						} else {
							header.details.filter((detail) => {
								if (detail.remarks === 'Tax Based Amount') {
									header.details.splice(0, 1);
								}
							});
						}

					}
				}

				
			});
		},
		atc_code_on_change(atc_code_value) {
			const taxCodesList = this.getDataAlphataxcodes;

			let filterAlphaTaxCode = taxCodesList.filter(taxCode => {
				return taxCode.atc_code === atc_code_value;
			});

			this.documentDetails.filter((header) => {
				if (header.atc_code === atc_code_value) {
					header.atc_rate = filterAlphaTaxCode[0].atc_rate;

					let filterDetails = [];

					if (header.document_type === 'CW') {
						filterDetails = header.cw_details.filter((detail) => {
							return detail.remarks === 'Tax Based Amount';
						});
					} else {
						filterDetails = header.details.filter((detail) => {
							return detail.remarks === 'Tax Based Amount';
						});
					}

					if (filterDetails.length > 0) { 
						filterDetails.forEach((detail) => {
							if (detail.remarks === 'Tax Based Amount') {
								detail.debit_amount = (header.tax_base_amount ? header.tax_base_amount.replace(/,/g, '') : 0) * filterAlphaTaxCode[0].atc_rate;
								detail.credit_amount = 0;
							}
						});
					}
				}
			});
		},
		populateRegTrxData(document_number, reg_trx_Data) {
			this.getPayees();
			this.getAlphaTaxCodes();
			this.getDepts();
			this.getBranches();
			this.getSections();
			this.getCoas();
			let get_acct_entries = JSON.parse(reg_trx_Data.acct_entries);

			let filter_existing_entries = this.populate_entries.filter(function(entry) {
				return entry.document_number !== document_number;
			});

			this.populate_entries = filter_existing_entries;

			var that = this;

			this.documentDetails.forEach(function(header) {

				if (header.document_number === document_number) {
					header.txn_type = reg_trx_Data.txn_type;
					header.payee_id = reg_trx_Data.payee_id;
					header.atc_code = reg_trx_Data.atc_code;
					header.atc_rate = reg_trx_Data.atc_rate;
					header.currency_id = this.batchDetails.currency_id;
					header.dollar_rate = this.batchDetails.dollar_rate;
					header.vat = '12';
					header.remarks = reg_trx_Data.remarks;
					header.id_regular_trx_data = reg_trx_Data.id;
					header.details = that.populate_entries;

					get_acct_entries.forEach((entry) => {
						that.populate_entries.push({
							payee_id: entry.analysis,
							department_id: entry.department,
							section_id: entry.section,
							regionalhead_id: 1,
							branch_id: entry.branch,
							document_number: document_number,
							document_date: this.document_date,
							account_code: entry.account_code,
							remarks: entry.remarks,
							debit_amount: entry.debit,
							credit_amount: entry.credit,
						});
					});
				}

				that.populate_entries.forEach((detail, index) => {
					that.getDeptData(detail.department_id, index, document_number);
				});
			});
		},
		async generateCheque(counter, due_date, header, details, document_type) {
			let currentDueDate = format(new Date(due_date), 'yyyy-MM-dd');

			let distData = this.dist_data;
			let header_data = [];

			let data = { 
				document_date: this.document_date,
				document_type: document_type,
				edit_by: this.currUser.id
			};

			for (let i = 0; i < counter - 1; i++) {
				let due_date = addMonths(parseISO(currentDueDate), i + 1);

				let headerDoc = [];

				header_data.forEach((headerDocNum) => {
					if (document_type === 'CW') {
						headerDoc.push(headerDocNum.reference_number);
					} else if (document_type === 'CV') {
						headerDoc.push(headerDocNum.document_number);
					}
				});

				let datas = [];
				let detailData = {};

				if (document_type === 'CW') {
					distData.cheque_write.document_number.map(i => {
						detailData = {document_number: parseInt(i), matched: headerDoc.includes(parseInt(i))};
						datas.push(detailData);
					});
				} else if (document_type === 'CV') {
					distData.cheque.document_number.map(i => {
						detailData = {document_number: parseInt(i), matched: headerDoc.includes(parseInt(i))};
						datas.push(detailData);
					});
				}

				let num = datas.filter(det => {
					return det.matched === false; 
				});

				let unique_id = '';
				let get_generated_number = '';

				if(num.length > 1){
					unique_id = num[1].document_number;
				}
				
				if (document_type === 'CW') {
					if(!unique_id){
						data['batch_number'] = distData.cheque_write.batch_number;
						get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
							let data = distData.cheque_write;
							data['document_number'].push(response.data.data.document_number);
							unique_id = parseInt(response.data.data.document_number);
						});
					}
				} else if (document_type === 'CV') {
					let docNumCheque = [];
					get_generated_number = this.$store.dispatch('trxdisb/getDocumentNumberCheque').then(response => {
						docNumCheque = response.data.data;
					});

					unique_id = docNumCheque[0].voucher_number;
				}

				await get_generated_number;

				let detail_values = [];
				
				details.forEach(detail => {
					detail_values.push({
						section_id: detail.section_id,
						regionalhead_id: detail.regionalhead_id,
						document_number: (document_type == 'CW') ? '' : unique_id,
						document_date: (document_type == 'CW') ? '' : this.document_date,
						account_code: detail.account_code,
						debit_amount: detail.debit_amount,
						credit_amount: detail.credit_amount,
						branch_id: detail.branch_id,
						remarks: detail.remarks,
						reference_number: (document_type == 'CW') ? unique_id : '',
						payee_id: detail.payee_id,
						department_id: detail.department_id,
					});
				});

				let header_value = {
					company_id: header.company_id,
					batch_number: header.batch_number,
					bank_account_id: header.bank_account_id,
					regular_trx_type: header.regular_trx_type,
					disbursement_type: header.disbursement_type,
					document_number: (document_type == 'CW') ? '' : unique_id,
					document_date: (document_type == 'CW') ? '' : this.document_date,
					document_type: document_type,
					atc_code: header.atc_code,
					currency_id: header.currency_id,
					dollar_rate: header.dollar_rate,
					vat: header.vat,
					amount: header.amount,
					regular_trx_id: header.regular_trx_id,
					check_type: header.check_type,
					is_pdc: header.check_type === 'PDC' ? 1 : 0,
					due_date: format(new Date(due_date), 'yyyy-MM-dd'),
					reference_number: (document_type == 'CW') ? unique_id : '',
					details: detail_values,
					overallDebit: header.overallDebit,
					overallCredit: header.overallCredit,
					payee_id: header.payee_id,
					payee_account_id: header.payee_account_id,
					remarks: header.remarks,
					cheque_date: header.cheque_date,
					cheque_number: header.cheque_number,
					voucher_amount: header.voucher_amount,
					voucher_date: header.due_date,
					is_confidential: header.is_confidential,
				};

				if(document_type == 'CV'){
					header_value.cheque_amount = header.cheque_amount;
					header_value.cheque_date = header.cheque_date;
					header_value.cheque_number = header.cheque_number;
					header_value.voucher_amount = header.voucher_amount;
					header_value.voucher_date = header.voucher_date;
				}

				if(document_type == 'CW'){
					header_value.cheque_amount = header.cheque_amount;
					header_value.cheque_date = header.cheque_date;
					header_value.cheque_number = header.cheque_number;
					header_value.voucher_amount = header.voucher_amount;
					header_value.voucher_date = header.due_date;
				}

				header_data.push(header_value);
				this.generated_cheques.push(header_value);
			}

			return new Promise(resolve => {
				setTimeout(() => {
					resolve(this.generated_cheques);
				}, 1500);
			});
		},
		async update(batch_number){
			this.isvalid = this.$refs.form.validate();
			//this.documentDetails.splice(1);

			if (this.isvalid) {
				let data = {};
				let detail = [];
				let header = [];
				let dollar_rate = 0;
				// var ifRegularTypeSelected = false;
				let get_generated_cheques = [];
				let cheque_count = 0;

				data = {};
				header = [];
				detail = [];

				data.headers = header;
				data.details = detail;

				data.image_title = this.image_title;
				data.upload_file = this.upload_file;

				this.documentDetails.forEach((header, index) => {
					if (header.batch_number === batch_number) {
						
						
						let payeeItems = header.payee_id;

						if (typeof header.payee_id === 'number') {
							payeeItems = header.payee_id;
						} else if (typeof header.payee_id === 'object') {
							payeeItems = header.payee_id.id;
						}

						let totalDebit = typeof header.overallDebit === 'string' ? header.overallDebit.replace(/,/g, ''): header.overallDebit;
						let totalCredit = typeof header.overallCredit === 'string' ? header.overallCredit.replace(/,/g, '') : header.overallCredit;
						let totalAmount = 0;

						if(totalDebit < totalCredit){
							totalAmount = totalCredit;
						} else {
							totalAmount = totalDebit;
						}


						let headerObject = {
							cheque_amount: header.cheque_amount,
							company_id: header.company_id,
							batch_number: header.batch_number,
							document_number: header.document_type == 'CW' ? '' : header.document_number,
							document_type: header.document_type,
							atc_code: header.atc_code,
							currency_id: this.$data.currency_id,
							dollar_rate: this.$data.currency_id === 2 ? this.$data.dollar_rate : dollar_rate,
							vat: header.vat,
							amount: totalAmount,
							payee_id: payeeItems ? payeeItems : 0,
							overallDebit: typeof header.overallDebit === 'string' ? header.overallDebit.replace(/,/g, '') : header.overallDebit,
							overallCredit: typeof header.overallCredit === 'string' ? header.overallCredit.replace(/,/g, '') : header.overallCredit,
							document_date:  header.document_type == 'CW' ? '' : format(parseISO(this.document_date), 'yyyy-MM-dd'),
							is_confidential: header.is_confidential,
							remarks: header.remarks,
							regular_trx_id: header.regular_trx_id,
							regular_trx_type: this.regular_trx_type[index],
							disbursement_type: header.disbursement_type,
							id_regular_trx_data: header.id_regular_trx_data,
							bank_account_id: header.bank_account_id,
							edit_by: this.currUser.id,
							payee_account_id: header.payee_account_id,
							cheque_date: header.cheque_date,
							cheque_number: header.cheque_number,
							voucher_amount: header.voucher_amount,
							voucher_date: header.voucher_date,
							reference_number: header.document_type == 'CW' ? header.reference_number : (this.documentDetails[0].integrate_from === 3 ? header.reference_number:''),
							integrate_from: this.documentDetails[0].integrate_from,
							due_date: header.due_date,
							auto_debit: header.auto_debit,
							approved_remarks: header.approved_remarks,
							status: this.getStatus(header.auto_debit),
							is_pdc: header.check_type === 'PDC' ? 1 : 0,
							regtrx_indicator: header.regtrx_indicator,
							po_payment: this.payment_amount == 0 ? 0 : parseFloat(this.payment_amount.replace(/,/g, ''))
						};

						headerObject.overallDebit = parseFloat(typeof headerObject.overallDebit === 'string' ? headerObject.overallDebit.replace(/,/g, '') : headerObject.overallDebit);
						headerObject.overallCredit = parseFloat(typeof headerObject.overallCredit === 'string' ? headerObject.overallCredit.replace(/,/g, '') : headerObject.overallCredit);

						if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
							headerObject.payee_account_id = 0;
						}

						data.headers.push(headerObject);
						
						if (header.document_type === 'CW') {
							header.cw_details.filter((detail) => {

								if (detail.reference_number == header.reference_number) {
									let sec_id = detail.section_id;
									if(detail.section_id === '' || detail.section_id === undefined){
										sec_id = 0;
									}
									let detailObject = {
										section_id: sec_id,
										regionalhead_id: sec_id,
										batch_number: detail.batch_number,
										document_number: header.document_type === 'CW' ? '' : detail.document_number,
										document_date: header.document_type === 'CW' ? '' : this.document_date,
										remarks: detail.remarks,
										account_code: detail.account_code,
										department_id:  detail.department_id,
										branch_id:  detail.branch_id,
										currency_id: detail.currency_id,
										debit_amount: typeof detail.debit_amount === 'string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount,
										credit_amount: typeof  detail.credit_amount=== 'string' ? detail.credit_amount.replace(/,/g, '') :  detail.credit_amount,
										payee_id: detail.payee_id,
										reference_number: header.document_type === 'CW' ? header.reference_number : ''
									};

									data.details.push(detailObject);
								}

								this.setDetailValue(detail.payee_id, index, header.reference_number, detail.section_id, detail.branch_id, detail.department_id);
							});
						} else {
							header.details.filter((detail) => {
								if (detail.document_number == header.document_number) {
									let sec_id = detail.section_id;
									if(detail.section_id === '' || detail.section_id === undefined){
										sec_id = 0;
									}
									let detailObject = {
										section_id: sec_id,
										regionalhead_id: sec_id,
										batch_number: detail.batch_number,
										document_number: detail.document_number,
										document_date: this.document_date,
										remarks: detail.remarks,
										account_code: detail.account_code,
										department_id:  detail.department_id,
										currency_id: detail.currency_id,
										branch_id:  detail.branch_id,
										debit_amount: typeof detail.debit_amount === 'string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount,
										credit_amount: typeof  detail.credit_amount=== 'string' ? detail.credit_amount.replace(/,/g, '') :  detail.credit_amount,
										payee_id: detail.payee_id
									};

									data.details.push(detailObject);
								}

								this.setDetailValue(detail.payee_id, index, detail.document_number, detail.section_id, detail.branch_id, detail.department_id);
							});
						}

						if ((header.document_type == 'CW' && header.is_pdc == 1) || (header.document_type == 'CV' && header.is_pdc == 1)) {
							cheque_count = header.cheque_count;
							get_generated_cheques = this.generateCheque(header.cheque_count, header.due_date, header, data.details, this.batchDetails.document_type);
						}
					}
				});

				const filterRegTrx = data.headers.filter(header => header.regular_trx_id > 0);

				if (filterRegTrx.length > 0) { 
					this.updateRegularTrx(filterRegTrx, data);
				}

				data.headers.forEach((header) => {
					if (header.batch_number === batch_number) {
						header.status = this.getStatus(header.auto_debit);
						delete header.disbursement_type;
						delete header.id_regular_trx_data;
						delete header.regular_trx_type;
					}
					
					if(header.atc_code === '' || header.atc_code === undefined || header.atc_code === null){
						header.atc_code = 0;
					}

					if(header.voucher_amount === '' || header.voucher_amount === undefined || header.voucher_amount === null){
						header.voucher_amount = 0;
					}
					if(header.document_type === '' || header.document_type === undefined || header.document_type === null){
						header.document_type = 'DM';
					}
				});

				if ((this.batchDetails.document_type == 'CW' && data.headers[0].is_pdc == 1) || (this.batchDetails.document_type == 'CV' && data.headers[0].is_pdc == 1)) {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });
					await get_generated_cheques;
					this.$store.commit('app/SET_IS_LOADING', false, { root: true });
					

					if (parseInt(cheque_count - 1) === this.generated_cheques.length) {
						// this.generated_cheques.splice(0, 1);
						this.generated_cheques.forEach(headerData => {
							let payeeItemsHeaders = headerData.payee_id;

							if (typeof headerData.payee_id === 'number') {
								payeeItemsHeaders = headerData.payee_id;
							} else if (typeof headerData.payee_id === 'object') {
								payeeItemsHeaders = headerData.payee_id.id;
							}

							data.headers.push({
								company_id: headerData.company_id,
								batch_number: headerData.batch_number,
								document_number: headerData.document_type == 'CW' ? ' ': headerData.document_number,
								atc_code: headerData.atc_code ? headerData.atc_code : 0,
								currency_id: headerData.currency_id,
								dollar_rate: headerData.dollar_rate,
								is_pdc: headerData.is_pdc,
								vat: headerData.vat,
								amount: headerData.amount,
								regular_trx_id: headerData.regular_trx_id ? headerData.regular_trx_id : process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER,
								overallDebit: headerData.overallDebit.replace(/,/g, ''),
								overallCredit: headerData.overallCredit.replace(/,/g, ''),
								payee_id: payeeItemsHeaders,
								remarks: headerData.remarks,
								status: this.getStatus(headerData.auto_debit),
								document_date: this.document_date,
								is_confidential: headerData.is_confidential,
								document_type: headerData.document_type,
								bank_account_id: headerData.bank_account_id,
								edit_by: this.currUser.id,
								payee_account_id: headerData.payee_account_id,
								reference_number: headerData.document_type == 'CW' ? headerData.reference_number : '',
								due_date: headerData.due_date,
								auto_debit: (headerData.auto_debit !== undefined) ? headerData.auto_debit : 0,
								cheque_date: headerData.cheque_date,
								cheque_number: headerData.cheque_number,
								cheque_amount: headerData.cheque_amount,
								voucher_amount: headerData.voucher_amount,
								voucher_date: headerData.due_date
							});

							headerData.details.forEach(detail => {
								data.details.push({
									batch_number: headerData.batch_number,
									account_code: detail.account_code,
									branch_id: detail.branch_id,
									credit_amount: detail.credit_amount,
									debit_amount: detail.debit_amount,
									department_id: detail.department_id,
									document_date: headerData.document_type == 'CW' ? ' ': this.document_date,
									document_number: headerData.document_type == 'CW' ? ' ': this.document_date,
									payee_id: detail.payee_id,
									regionalhead_id: detail.regionalhead_id,
									currency_id: detail.currency_id,
									remarks: detail.remarks,
									section_id: detail.section_id,
									reference_number: headerData.document_type == 'CW' ? headerData.reference_number : ''
								});
							});
						});
					}
				}

				if(this.upload_files.length != 0){
					for( var i = 0; i < this.upload_files.length; i++ ){
						let file = this.upload_files[i];
						data.image_title.push(file.name);
						data.upload_file.push(file);
					}
				}
				data.delete_file = this.delete_file;
				// for( var j = 0; j < this.trxdisbDetailed.length; j++ ){
				// 	data.delete_file.push(this.trxdisbDetailed[j].filename);
				// }
				if(this.deleted_files.length > 0){
					for( var j = 0; j < this.deleted_files.length; j++ ){
						data.delete_file.push(this.deleted_files[j]);
					}
				}

				this.executeEntries(data.details, data, 'update-disbursement', 'disbursement');

				// if (ifRegularTypeSelected === true) {
				
				// } else {
				// 	this.snackbar = true;
				// 	this.updateDisbursement = false;
				// 	this.error_message = 'Regular Transaction Type is not selected. Please Choose';
				// }	
			} else {
				this.snackbar = true;
				this.updateDisbursement = false;
				this.error_message = 'Error: Please fill out the required fields';
			}	
		},
		async saveChanges(batch_number){
			this.isvalid = this.$refs.form.validate();
			//this.documentDetails.splice(1);

			if (this.isvalid) {
				let data = {};
				let detail = [];
				let header = [];
				let dollar_rate = 0;
				// var ifRegularTypeSelected = false;
				let get_generated_cheques = [];
				let cheque_count = 0;

				data = {};
				header = [];
				detail = [];

				data.headers = header;
				data.details = detail;

				data.image_title = this.image_title;
				data.upload_file = this.upload_file;

				this.documentDetails.forEach((header, index) => {
					if (header.batch_number === batch_number) {
						
						
						let payeeItems = header.payee_id;

						if (typeof header.payee_id === 'number') {
							payeeItems = header.payee_id;
						} else if (typeof header.payee_id === 'object') {
							payeeItems = header.payee_id.id;
						}

						let totalDebit = typeof header.overallDebit === 'string' ? header.overallDebit.replace(/,/g, ''): header.overallDebit;
						let totalCredit = typeof header.overallCredit === 'string' ? header.overallCredit.replace(/,/g, '') : header.overallCredit;
						let totalAmount = 0;

						if(totalDebit < totalCredit){
							totalAmount = totalCredit;
						} else {
							totalAmount = totalDebit;
						}

						let headerObject = {
							cheque_amount: header.cheque_amount,
							company_id: header.company_id,
							batch_number: header.batch_number,
							document_number: header.document_type == 'CW' ? '' : header.document_number,
							document_type: header.document_type,
							atc_code: header.atc_code,
							currency_id: this.$data.currency_id,
							dollar_rate: this.$data.currency_id === 2 ? this.$data.dollar_rate : dollar_rate,
							vat: header.vat,
							amount: totalAmount,
							payee_id: payeeItems ? payeeItems : 0,
							overallDebit: typeof header.overallDebit === 'string' ? header.overallDebit.replace(/,/g, '') : header.overallDebit,
							overallCredit: typeof header.overallCredit === 'string' ? header.overallCredit.replace(/,/g, '') : header.overallCredit,
							document_date:  header.document_type == 'CW' ? '' : format(parseISO(this.document_date), 'yyyy-MM-dd'),
							is_confidential: header.is_confidential,
							remarks: header.remarks,
							regular_trx_id: header.regular_trx_id,
							regular_trx_type: this.regular_trx_type[index],
							disbursement_type: header.disbursement_type,
							id_regular_trx_data: header.id_regular_trx_data,
							bank_account_id: header.bank_account_id,
							edit_by: this.currUser.id,
							payee_account_id: header.payee_account_id,
							cheque_date: header.cheque_date,
							cheque_number: header.cheque_number,
							voucher_amount: header.voucher_amount,
							voucher_date: header.voucher_date,
							reference_number: header.document_type == 'CW' ? header.reference_number : (this.documentDetails[0].integrate_from === 3 ? header.reference_number:''),
							integrate_from: this.documentDetails[0].integrate_from,
							due_date: header.due_date,
							auto_debit: header.auto_debit,
							status: 1,
							is_pdc: header.check_type === 'PDC' ? 1 : 0,
							po_payment: this.payment_amount == 0 ? 0 : parseFloat(this.payment_amount.replace(/,/g, ''))
						};

						headerObject.overallDebit = parseFloat(typeof headerObject.overallDebit === 'string' ? headerObject.overallDebit.replace(/,/g, '') : headerObject.overallDebit);
						headerObject.overallCredit = parseFloat(typeof headerObject.overallCredit === 'string' ? headerObject.overallCredit.replace(/,/g, '') : headerObject.overallCredit);

						if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
							headerObject.payee_account_id = 0;
						}

						data.headers.push(headerObject);
						
						if (header.document_type === 'CW') {
							header.cw_details.filter((detail) => {

								if (detail.reference_number == header.reference_number) {
									let sec_id = detail.section_id;
									if(detail.section_id === '' || detail.section_id === undefined){
										sec_id = 0;
									}
									let detailObject = {
										section_id: sec_id,
										regionalhead_id: sec_id,
										batch_number: detail.batch_number,
										document_number: header.document_type === 'CW' ? '' : detail.document_number,
										document_date: header.document_type === 'CW' ? '' : this.document_date,
										remarks: detail.remarks,
										account_code: detail.account_code,
										department_id:  detail.department_id,
										branch_id:  detail.branch_id,
										currency_id: detail.currency_id,
										debit_amount: typeof detail.debit_amount === 'string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount,
										credit_amount: typeof  detail.credit_amount=== 'string' ? detail.credit_amount.replace(/,/g, '') :  detail.credit_amount,
										payee_id: detail.payee_id,
										reference_number: header.document_type === 'CW' ? header.reference_number : ''
									};

									data.details.push(detailObject);
								}

								this.setDetailValue(detail.payee_id, index, header.reference_number, detail.section_id, detail.branch_id, detail.department_id);
							});
						} else {
							header.details.filter((detail) => {
								if (detail.document_number == header.document_number) {
									let sec_id = detail.section_id;
									if(detail.section_id === '' || detail.section_id === undefined){
										sec_id = 0;
									}
									let detailObject = {
										section_id: sec_id,
										regionalhead_id: sec_id,
										batch_number: detail.batch_number,
										document_number: detail.document_number,
										document_date: this.document_date,
										remarks: detail.remarks,
										account_code: detail.account_code,
										department_id:  detail.department_id,
										currency_id: detail.currency_id,
										branch_id:  detail.branch_id,
										debit_amount: typeof detail.debit_amount === 'string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount,
										credit_amount: typeof  detail.credit_amount=== 'string' ? detail.credit_amount.replace(/,/g, '') :  detail.credit_amount,
										payee_id: detail.payee_id
									};

									data.details.push(detailObject);
								}

								this.setDetailValue(detail.payee_id, index, detail.document_number, detail.section_id, detail.branch_id, detail.department_id);
							});
						}

						if ((header.document_type == 'CW' && header.is_pdc == 1) || (header.document_type == 'CV' && header.is_pdc == 1)) {
							cheque_count = header.cheque_count;
							get_generated_cheques = this.generateCheque(header.cheque_count, header.due_date, header, data.details, this.batchDetails.document_type);
						}
					}
				});

				const filterRegTrx = data.headers.filter(header => header.regular_trx_id > 0);

				if (filterRegTrx.length > 0) { 
					this.updateRegularTrx(filterRegTrx, data);
				}

				data.headers.forEach((header) => {
					if (header.batch_number === batch_number) {
						header.status = 1;
						delete header.disbursement_type;
						delete header.id_regular_trx_data;
						delete header.regular_trx_type;
					}
					
					if(header.atc_code === '' || header.atc_code === undefined || header.atc_code === null){
						header.atc_code = 0;
					}

					if(header.voucher_amount === '' || header.voucher_amount === undefined || header.voucher_amount === null){
						header.voucher_amount = 0;
					}
					if(header.document_type === '' || header.document_type === undefined || header.document_type === null){
						header.document_type = 'DM';
					}
				});

				if ((this.batchDetails.document_type == 'CW' && data.headers[0].is_pdc == 1) || (this.batchDetails.document_type == 'CV' && data.headers[0].is_pdc == 1)) {
					this.$store.commit('app/SET_IS_LOADING', true, { root: true });
					await get_generated_cheques;
					this.$store.commit('app/SET_IS_LOADING', false, { root: true });
					

					if (parseInt(cheque_count - 1) === this.generated_cheques.length) {
						// this.generated_cheques.splice(0, 1);
						this.generated_cheques.forEach(headerData => {
							let payeeItemsHeaders = headerData.payee_id;

							if (typeof headerData.payee_id === 'number') {
								payeeItemsHeaders = headerData.payee_id;
							} else if (typeof headerData.payee_id === 'object') {
								payeeItemsHeaders = headerData.payee_id.id;
							}

							data.headers.push({
								company_id: headerData.company_id,
								batch_number: headerData.batch_number,
								document_number: headerData.document_type == 'CW' ? ' ': headerData.document_number,
								atc_code: headerData.atc_code ? headerData.atc_code : 0,
								currency_id: headerData.currency_id,
								dollar_rate: headerData.dollar_rate,
								is_pdc: headerData.is_pdc,
								vat: headerData.vat,
								amount: headerData.amount,
								regular_trx_id: headerData.regular_trx_id ? headerData.regular_trx_id : process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER,
								overallDebit: headerData.overallDebit.replace(/,/g, ''),
								overallCredit: headerData.overallCredit.replace(/,/g, ''),
								payee_id: payeeItemsHeaders,
								remarks: headerData.remarks,
								status: 1,
								document_date: this.document_date,
								is_confidential: headerData.is_confidential,
								document_type: headerData.document_type,
								bank_account_id: headerData.bank_account_id,
								edit_by: this.currUser.id,
								payee_account_id: headerData.payee_account_id,
								reference_number: headerData.document_type == 'CW' ? headerData.reference_number : '',
								due_date: headerData.due_date,
								auto_debit: (headerData.auto_debit !== undefined) ? headerData.auto_debit : 0,
								cheque_date: headerData.cheque_date,
								cheque_number: headerData.cheque_number,
								cheque_amount: headerData.cheque_amount,
								voucher_amount: headerData.voucher_amount,
								voucher_date: headerData.due_date
							});

							headerData.details.forEach(detail => {
								data.details.push({
									batch_number: headerData.batch_number,
									account_code: detail.account_code,
									branch_id: detail.branch_id,
									credit_amount: detail.credit_amount,
									debit_amount: detail.debit_amount,
									department_id: detail.department_id,
									document_date: headerData.document_type == 'CW' ? ' ': this.document_date,
									document_number: headerData.document_type == 'CW' ? ' ': detail.document_number,
									payee_id: detail.payee_id,
									regionalhead_id: detail.regionalhead_id,
									currency_id: detail.currency_id,
									remarks: detail.remarks,
									section_id: detail.section_id,
									reference_number: headerData.document_type == 'CW' ? headerData.reference_number : ''
								});
							});
						});
					}
				}

				if(this.upload_files.length != 0){
					for( var i = 0; i < this.upload_files.length; i++ ){
						let file = this.upload_files[i];
						data.image_title.push(file.name);
						data.upload_file.push(file);
					}
				}
				data.delete_file = this.delete_file;
				// for( var j = 0; j < this.trxdisbDetailed.length; j++ ){
				// 	data.delete_file.push(this.trxdisbDetailed[j].filename);
				// }

				if(this.deleted_files.length > 0){
					for( var j = 0; j < this.deleted_files.length; j++ ){
						data.delete_file.push(this.deleted_files[j]);
					}
				}

				this.executeEntries(data.details, data, 'update-disbursement', 'save-draft');

				// if (ifRegularTypeSelected === true) {
				
				// } else {
				// 	this.snackbar = true;
				// 	this.updateDisbursement = false;
				// 	this.error_message = 'Regular Transaction Type is not selected. Please Choose';
				// }	
			} else {
				this.snackbar = true;
				this.updateDisbursement = false;
				this.error_message = 'Error: Please fill out the required fields';
			}	
		},
		cancel(batch_number) {
			let data = {};
			data.batch_number = batch_number;
			data.value = {
				status: 20,
				processing: 0,
				_method: 'PUT'
			};
			
			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			data.value.document_type = this.batchDetails.document_type;

			let convertedFormData = jsonToFormData(data.value, options);
			this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
			//this.$store.dispatch('trxdisb/updateTrxdisbStatus', data);
			this.$store.dispatch('trxdisb/setEditDialog', false);
		},
		closeDialog(batch_number) {
			
			this.documentDetails.forEach((header) => {
				if (header.batch_number === batch_number) {
					let data = {};
										
					data.batch_number = header.batch_number;
					data.value = {
						processing: 0,
						status: 1,
						document_type: this.batchDetails.document_type,
						_method: 'PUT'
					};

					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};

					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
					//this.$store.dispatch('trxdisb/updateTrxdisbStatus', data);
				}
			});

			
			this.$store.dispatch('trxdisb/setEditDialog', false);
		},
		updateRegularTrx(filterRegTrx, data) {
			let regularTrx = {};

			filterRegTrx.forEach((trxData) => {
				const filterDetails = data.details.filter(detail => detail.document_number === trxData.document_number);
				let acctEntriesData = [];
				acctEntriesData = [];
				let sec_id = 0;

				filterDetails.forEach((detail) => {
					sec_id = detail.section_id;

					if(detail.section_id === '' || detail.section_id === undefined){
						sec_id = 0;
					}

					acctEntriesData.push({
						'analysis': detail.payee_id,
						'department': detail.department_id,
						'branch': detail.branch_id,
						'currency': detail.currency_id,
						'section': sec_id,
						'account_code': detail.account_code,
						'debit': typeof  detail.debit_amount === 'string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount,
						'credit': typeof  detail.credit_amount=== 'string' ? detail.credit_amount.replace(/,/g, '') : detail.credit_amount
					});
				});

				this.dataRegularTrx.filter(regularTrxData => {
					if(trxData.document_number == regularTrxData.doc_number){
						// payee_name: '1',
						// payee_bank_id: trxData.payee_account_id,
						// payee_bank_name: '1',

						let payeeBank = [];

						regularTrx = {
							id: regularTrxData.id,
							txn_type: regularTrxData.txn_type,
							doc_number: trxData.document_number,
							document_type: trxData.document_type,
							payee_bank_id: trxData.payee_account_id,
							atc_code: trxData.atc_code,
							atc_rate: trxData.atc_rate ? trxData.atc_rate : 0,
							remarks: trxData.remarks,
							acct_entries: JSON.stringify(acctEntriesData),
							edit_by: this.currUser.id
						};

						if (typeof trxData.payee_id !== 'number' ) {
							payeeBank = trxData.payee_id.payee_banks.filter(bank => bank.payee_account_id === regularTrxData.payee_account_id);
							regularTrx.payee_name = trxData.payee_id.payee_desc; 
							regularTrx.payee_id = trxData.payee_id.id;
							regularTrx.payee_bank_name = payeeBank[0].item_data;
						} else {
							regularTrx.payee_name = '1';
							regularTrx.payee_id = trxData.payee_id;
							regularTrx.payee_bank_name = 1;
						}

						if(regularTrx.atc_code === '' || regularTrx.atc_code === undefined || regularTrx.atc_code === null){
							regularTrx.atc_code = 0;
						}

						if (trxData.regular_trx_type === '2') {
							regularTrx.doc_number = trxData.document_number;
							regularTrx.is_approved = 0;
							this.executeEntries(filterDetails, regularTrx, 'save-regulartrx', 'regular-trx');
						} else {
							regularTrx.id = trxData.regular_trx_id;
							this.executeEntries(filterDetails, regularTrx, 'update-regulartrx', 'regular-trx');
						}
					}
				});
			});
		},
		executeEntries(details, payload, method, type) {
			//Initialize Checkers
			let hasDepts = [];
			let hasBranches = [];
			let hasSection = [];
			let unBalanced = [];
			let hasPayeeBank = [];
			let hasCOA = [];
			let hasRemarks = [];
			let hasChequeCount = [];
			let uncollectedAmount = [];
			let partialPayment = [];
			let diffCurrency = [];

			details.forEach(detail => {
				if (detail.department_id === undefined || detail.department_id === '' || detail.department_id === 0) {
					hasDepts.push({dept: false});
				}

				if (detail.branch_id === undefined || detail.branch_id === '') {
					hasBranches.push({branch: false});
				} 

				if(detail.account_code === undefined || detail.account_code === ''){
					hasCOA.push({coa: false});
				}
				

				if(detail.account_code){
					let det = this.coaData.filter(coa => {return coa.acct_code === detail.account_code;});
					if(det[0].currency_id !== this.currency_id){
						diffCurrency.push({currency: false});
					}
					detail.currency_id = det[0].currency_id;
				}

				if (detail.department_id){
					let det = this.dataDepts.filter(dept => { return dept.id === detail.department_id; });
					if(det.length !== 0){
						if(det[0].sections.length !== 0 && detail.section_id === '' || det[0].sections.length !== 0 && detail.section_id === undefined || det[0].sections.length !== 0 && detail.section_id === 0){
							hasSection.push({department: det[0].dept_desc, section: false});
						}
					}
				}

				if(Object.keys(this.po_request).length !== 0){
					if(parseFloat(this.payment_amount.replace(/,/g, '')) > (parseFloat(this.remaining_amount.replace(/,/g, '')) )){
						uncollectedAmount.push({uncollect: true});
					}
					if(this.po_request.is_partial_payment == 0 && (parseFloat(this.payment_amount.replace(/,/g, '')) != parseFloat(this.po_request.amount.replace(/,/g, '')))){
						partialPayment.push({partial: true});
					}
				}
			});

			switch(type) {
			case 'regular-trx':
				if(payload.remarks === '' || payload.remarks === undefined){
					hasRemarks.push({remarks: true});
				}

				if(payload.atc_code === '' || payload.atc_code === undefined || payload.atc_code === null){
					payload.atc_code = 0;
				}
				
				break;
			case 'disbursement':
				payload.headers.forEach(headerObject => {
					if(headerObject.overallDebit != headerObject.overallCredit){
						unBalanced.push({unbal: true});
					}

					if(headerObject.remarks === '' || headerObject.remarks === undefined){
						hasRemarks.push({remarks: true});
					}

					if(headerObject.payeeItemsHeaders){
						let pay = this.payees.filter(pay => { return pay.id === headerObject.payeeItemsHeaders; });
						if (pay[0].payee_banks.length !== 0) {
							if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
								hasPayeeBank.push({payee: false});
							}
						}
					}

					if(headerObject.cheque_count === '' || headerObject.cheque_count === undefined){
						hasChequeCount.push({cheque_count: false});
					}
				});
				break;
			case 'save-draft':
				payload.headers.forEach(headerObject => {
					if(headerObject.remarks === '' || headerObject.remarks === undefined){
						hasRemarks.push({remarks: true});
					}

					if(headerObject.payeeItemsHeaders){
						let pay = this.payees.filter(pay => { return pay.id === headerObject.payeeItemsHeaders; });
						if (pay[0].payee_banks.length !== 0) {
							if(headerObject.payee_account_id === '' || headerObject.payee_account_id === undefined){
								hasPayeeBank.push({payee: false});
							}
						}
					}

					if(headerObject.cheque_count === '' || headerObject.cheque_count === undefined){
						hasChequeCount.push({cheque_count: false});
					}
				});
				break;
			}

			// These values demonstrate the default options
			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			payload._method = 'PUT';
			var convertedFormData = jsonToFormData(payload, options);

			if (hasDepts.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Department is missing from the field.';
				this.saveChange = false;
				this.updateDisbursement = false;
			} else if (hasBranches.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Branch is missing from the field.';
				this.saveChange = false;
				this.updateDisbursement = false;
			} else if (hasSection.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'Section is required in '+ hasSection[0].department +'.';
				this.saveChange = false;
				this.updateDisbursement = false;
			} else if (hasCOA.length != 0) {
				this.snackbar = true;
				this.$data.error_message = 'COA is missing from the field.';
				this.saveChange = false;
				this.updateDisbursement = false;
			} else if(unBalanced.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Unbalanced Entries.';
				this.updateDisbursement = false;
			} else if(uncollectedAmount.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payment Amount is greater than unpaid amount.';
			} else if(partialPayment.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payment Amount should be equal to PO amount.';
			} else if (hasRemarks.length !== 0) {
				this.snackbar = true;
				this.$data.error_message = 'Remarks is missing from the field.';
				this.saveChange = false;
				this.updateDisbursement = false;
			} else if (hasChequeCount.length !== 0 && payload.headers[0].is_pdc == 1) {
				this.snackbar = true;
				this.$data.error_message = 'Cheque Count is Required for Generating Cheques.';
				this.saveChange = false;
				this.updateDisbursement = false;

			}  else if(hasPayeeBank.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Payee Bank is missing from the field.';
				this.saveChange = false;
				this.updateDisbursement = false;
			} else if(diffCurrency.length != 0){
				this.snackbar = true;
				this.$data.error_message = 'Transaction detail currency is different from header.';
				this.saveChange = false;
				this.updateDisbursement = false;
			// }else if(this.upload_files.length === 0 && this.trxdisbDetailed.length === 0){
			// 	this.snackbar = true;
			// 	this.$data.error_message = 'Attachment is missing from the field.';
			} else {
				switch(method) {
				case 'save-regulartrx':
					this.$store.dispatch('regularTxns/saveregularTxns', payload);
					break;
				case 'update-regulartrx':
					this.$store.dispatch('regularTxns/updateregularTxns', payload);
					break;
				case 'update-disbursement':
					this.$store.dispatch('trxdisb/updateTrxdisb', {batch_number: payload.headers[0].batch_number, value: convertedFormData});
					break;
				}
			}
			this.updateDisbursement = false;
		},
		filterDetailsByDocumentNumber(id) {
			if (this.batchDetails.document_type === 'CW') {
				return this.documentDetails.filter(detail => detail.reference_number === id);
			} else {
				return this.documentDetails.filter(detail => detail.document_number === id);
			}
		},
		setDetailValue(id, key, header_document, section_id, branch_id, department_id){
			// this.getBranches();
			// this.getSections();
			// this.getDepts();

			const filterDetails = this.filterDetailsByDocumentNumber(header_document);

			let details = [];

			if (filterDetails.length > 0) {
				if (this.batchDetails.document_type === 'CW') {
					details = filterDetails[0].cw_details;
				} else {
					details = filterDetails[0].details;
				}

				
				//filterDetails[key].section_id = section_id;
				details[key].branch_id = branch_id;
				details[key].section_id = section_id;
				details[key].department_id = department_id;

				this.payees.filter(payee_details => {
					if(payee_details.id == id) {
						this.branches.filter(branches_details => {

							if(branches_details.brnc_code == payee_details.brnc_code){
								details[key].branch_id = branches_details.id;
							}
						});
						this.dataDepts.filter(depts_details => {
							if(depts_details.dept_code == payee_details.dept_code){
								details[key].department_id = depts_details.id;
							}
						});
						this.getDeptData(details[key].department_id, key, header_document);
					}
				});
			}
		},
		changeAccount(val){
			let account_code = '';
			let acct_class = '';

			this.dataBanks.filter(bankDetails => {
				if(bankDetails.id == val){
					account_code = bankDetails.acct_code;
					acct_class = bankDetails.acct_clss;
				}
			});

			let account_id = '';
			if(account_code){
				this.coaData.filter(account => {
					if(account.acct_code == account_code){
						account_id = account.id;
					}
				});
			}
			if(account_id){
				this.documentDetails.filter((header) => {
					header.details.forEach((detailData) => {
						detailData.account_code = parseInt(account_code);
					});
				});	
			}
			this.currency.filter(det => {
				if(det.cur_code === acct_class){
					this.currency_id = det.id;
				}
			});
			this.changeCOA();
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		getDefaultCurrency() {
			let acct_class = '';
			this.dataBanks.filter(det => {
				if(det.id === this.batchDetails.bank_account_id){
					acct_class = det.acct_clss;
				}
			});

			this.currency.filter(det => {
				if(det.cur_code === acct_class){
					this.batchDetails.currency_id = det.id;
				}
			});
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
			let dept = this.dataDepts.filter(e => e.dept_status === 1);
			this.dataDepts = dept;
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				let dataCoas = this.coas.data.filter(e => e.acct_status === 1);
				let nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							dataCoas.push(details);
						});
					});
					page++;
				}
				
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
		async getDataPayee(){
			this.dataPayee = this.payees.data;
			this.nextPagePayee= this.payees.next_page_url;
			let page = 2;

			while (this.nextPagePayee !== null){
				await this.$store.dispatch('payefile/getData', page).then(response => {
					this.nextPagePayee = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataPayee.push(details);
					});
				});
				page++;
			}
		},
		async getAcctperiodmain(){
			this.dataAcctmainperiod = this.acctperiodmains.data;
			this.nextPageAcctmainperiod= this.acctperiodmains.next_page_url;
			let page = 2;

			while (this.nextPageAcctmainperiod !== null){
				await this.$store.dispatch('acctperiodmain/getData', page).then(response => {
					this.nextPageAcctmainperiod = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAcctmainperiod.push(details);
					});
				});
				page++;
			}
		},
		async getDataBank(){
			this.dataBanks = this.banks.data;
			this.nextPageBanks = this.banks.next_page_url;
			let page = 2;

			while (this.nextPageBanks !== null){
				await this.$store.dispatch('bank/getData', page).then(response => {
					this.nextPageBanks = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataBanks.push(details);
					});
				});
				page ++;
			}
			
			this.dataBanks.forEach(det => {
				det['code_name'] = det.bank_code + ' - ' + det.bank_acct;
			});
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		async getDataSections(){
			this.dataSections = this.sections.data;
			this.nextPageSections= this.sections.next_page_url;
			let page = 2;

			while (this.nextPageSections !== null){
				await this.$store.dispatch('section/getData', page).then(response => {
					this.nextPageSections = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataSections.push(details);
					});
				});
				page++;
			}
		},
		async getDataRegularTrx(){
			this.dataRegularTrx = this.regulartrx.data;
			this.nextPageRegularTrx= this.regulartrx.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('regularTxns/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataRegularTrx.push(details);
					});
				});
				page++;
			}
		},
		getStatus(auto_debit){
			let status = 3;
			if(auto_debit === '1'){
				status = 6;
			} else if (this.currUser.max_amount !== null && parseFloat(this.currUser.max_amount) === parseFloat(this.level1.max_amount)){
				status = 4;
			} else if (this.currUser.max_amount !== null && parseFloat(this.currUser.max_amount) === parseFloat(this.level2.max_amount)){
				status = 5;
			}
			return status;
		}
	}
};
</script>
